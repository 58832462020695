import React, { useMemo } from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import type { HeadFC } from "gatsby"
import { SEO, Button, HeadingLine, Posts, Achievements } from "../components"
import { DefaultLayout } from "../layouts"
import { PostNodes, MarkdownPostInterface } from "../typings/post.interface"
import { PostFormat } from "../lib/post-format"
import { AchievementInterface } from "../typings/achievement.interface"

type IndexProps = {
    data: {
        articles: PostNodes
        writeups: PostNodes
        achievements: {
            nodes: AchievementInterface[]
        }
    }
}

const IndexPage = (props: IndexProps) => {
    const formattedArticles: MarkdownPostInterface[] = useMemo(
        () => PostFormat(props.data.articles.nodes),
        [props.data.articles.nodes]
    )
    const formattedWriteups: MarkdownPostInterface[] = useMemo(
        () => PostFormat(props.data.writeups.nodes),
        [props.data.writeups.nodes]
    )
    return (
        <DefaultLayout>
            <div className="font-inconsolata text-center my-10">
                <h1 className="font-semibold text-4xl">
                    Cyber Community Universitas Gunadarma
                </h1>
                <p>CTF Team Universitas Gunadarma</p>
                <StaticImage
                    src="../images/ccug.png"
                    className="mx-auto w-80 h-auto pointer-events-none"
                    alt="CCUG Logo"
                />
                <p>Tertarik dengan dunia cyber security?</p>
                <div className="flex justify-center py-5">
                    <Button href="/about_us">about_us</Button>
                    <Button href="/getting_started">getting_started</Button>
                </div>
            </div>

            <section className="my-10">
                <HeadingLine>Recent Articles</HeadingLine>
                <Posts data={formattedArticles} />
            </section>
            
            <section className="my-10">
                <HeadingLine>Recent Writeups</HeadingLine>
                <Posts data={formattedWriteups} />
            </section>

            <section className="my-10">
                <HeadingLine>Recent Achievements</HeadingLine>
                <Achievements data={props.data.achievements.nodes} />
            </section>
        </DefaultLayout>
    )
}

export const IndexQuery = graphql`
    query IndexQuery {
        articles: allMarkdownRemark(
            filter: {
                frontmatter: { draft: { eq: false } }
                fields: { detail: { postType: { eq: "article" } } }
            }
            limit: 5
        ) {
            nodes {
                frontmatter {
                    title
                    slug
                    date(formatString: "DD MMMM YYYY", locale: "id-ID")
                }
            }
        }
        writeups: allMarkdownRemark(
            filter: {
                frontmatter: { draft: { eq: false } }
                fields: { detail: { postType: { eq: "writeup" } } }
            }
            limit: 5
        ) {
            nodes {
                frontmatter {
                    title
                    slug
                    date(formatString: "DD MMMM YYYY", locale: "id-ID")
                }
            }
        }
        achievements: allAchievementsYaml(limit: 6) {
            nodes {
                title
                rank
                year
                held_by
                logo {
                    publicURL
                }
            }
        }
    }
`

export default IndexPage

export const Head: HeadFC = () => <SEO title="Home" />
